<template>
  <div>
    <a-modal
      v-if="config.type !== 'detail'"
      v-model="config.show"
      style="padding: 0px; margin-top: 0px"
      :width="config.width"
      :title="config.title"
      :okText="config.okText"
      @ok="handleOk"
      @cancel="handleCancel"
      :confirm-loading="confirmLoading"
    >
      <div v-if="config.type === 'open'">
        <div class="modal_text">
          <img src="../../assets/warn.png" style="width: 26px" alt="" />
          <span class="modal_logo">是否启用此租户?</span>
        </div>
      </div>

      <div v-if="config.type === 'close'">
        <div class="modal_text">
          <img src="../../assets/warn.png" style="width: 26px" alt="" />
          <span class="modal_logo">是否停用此租户?</span>
        </div>
        <!-- <div class="modal_text modal_stext">停用此账号后，此账号将无法登录</div> -->
      </div>
      <div v-if="config.type === 'tenantChange'">
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="版本类型" prop="version">
            <span v-if="config.record.versionType === 1">标准版</span>
            <span v-else>专业版</span>
          </a-form-model-item>
          <a-form-model-item
            v-if="config.record.versionType === 1"
            label="用户上限"
            prop="maxUsers"
          >
            <a-input-number
              v-if="config.type === 'tenantChange'"
              placeholder="请输入"
              class="input_width"
              v-model="form.maxUsers"
              :step="1"
              :min="0"
              :precision="0"
            />
          </a-form-model-item>
          <a-form-model-item
            v-if="config.record.versionType === 1"
            label="外部应用数上限"
            prop="maxApps"
          >
            <a-input-number
              placeholder="请输入"
              class="input_width"
              v-model="form.maxApps"
              :step="1"
              :min="0"
              :precision="0"
            />
          </a-form-model-item>
          <a-form-model-item
            v-if="config.record.versionType === 2"
            label="CPU(核)"
            prop="maxCpu"
          >
            <a-select v-model="form.maxCpu" class="input_width">
              <a-select-option
                v-for="(item, index) in cpuList"
                :key="index"
                :value="item"
              >
                {{ item }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            v-if="config.record.versionType === 2"
            label="内存(G)"
            prop="maxMemory"
          >
            <a-select v-model="form.maxMemory" class="input_width">
              <a-select-option
                v-for="(item, index) in memoryList"
                :key="index"
                :value="item"
              >
                {{ item }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            v-if="config.record.versionType === 2"
            label="存储空间(G)"
            prop="maxStorage"
          >
            <a-input-number
              placeholder="请输入"
              class="input_width"
              v-model="form.maxStorage"
              :step="1"
              :min="0"
              :precision="0"
            />
          </a-form-model-item>
        </a-form-model>
      </div>
      <div v-if="config.type === 'domainChange'">
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="域名前缀" prop="domainPrefix">
            <a-input
              placeholder="请输入"
              class="input_width"
              v-model="form.domainPrefix"
            />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
    <a-modal
      :footer="null"
      v-if="config.type === 'detail'"
      v-model="config.show"
      style="padding: 0px; margin-top: 0px"
      :width="config.width"
      :title="config.title"
      :okText="config.okText"
      @ok="handleOk"
      @cancel="handleCancel"
      :confirm-loading="confirmLoading"
    >
      <div class="carriage">
        <div class="site right">当前等级</div>
        <div class="site">{{ config.record.levelName }}</div>
      </div>
      <div v-if="config.record.versionType === 1" class="carriage">
        <div class="site right">用户数</div>
        <div class="site">{{ maxUsers }}</div>
      </div>
      <div v-if="config.record.versionType === 1" class="carriage">
        <div class="site right">应用数</div>
        <div class="site">{{ maxApps }}</div>
      </div>
      <div v-if="config.record.versionType === 2" class="carriage">
        <div class="site right">CPU使用率</div>
        <div class="site">{{ maxCpu }}</div>
      </div>
      <div v-if="config.record.versionType === 2" class="carriage">
        <div class="site right">内存</div>
        <div class="site">{{ maxMemory }}</div>
      </div>
      <div v-if="config.record.versionType === 2" class="carriage">
        <div class="site right">存储空间(G)</div>
        <div class="site">{{ maxStorage }}</div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { sassTenantApi } from "@/api/sassTenant.js";

export default {
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          type: "",
          id: 0,
          show: false,
          record: null,
          title: "",
          width: "60%",
          okText: "",
        };
      },
    },
  },
  data() {
    //校验手机号
    // let validatorPhone = (rule, value, callback) => {
    //   if (!value) {
    //     callback(new Error("手机号码不能为空"));
    //   } else if (!/^1[3456789]\d{9}$/.test(value) && value) {
    //     callback(new Error("手机号码格式不正确"));
    //   } else {
    //     callback();
    //   }
    // };
    return {
      loading: false,
      confirmLoading: false,
      selectStatus: true,
      ruleStatus: true,
      maxUsers: "",
      maxApps: "",
      maxCpu: "",
      maxMemory: "",
      maxStorage: "",
      rowVersion: null,
      cpuList: [1, 2, 4, 8],
      memoryList: [1, 2, 4, 8, 16, 32, 64, 128],
      labelCol: { span: 6 },
      wrapperCol: { span: 15 },
      form: {
        version: 1,
        maxUsers: "",
        maxApps: "",
        maxCpu: "",
        maxMemory: "",
        maxStorage: "",
        domainPrefix: "",
      },
      rules: {
        version: [{ required: true }],
        maxUsers: [
          {
            required: true,
            message: "用户上限不能为空",
            trigger: "change",
          },
        ],
        maxApps: [
          {
            required: true,
            message: "外部应用数上限不能为空",
            trigger: "blur",
          },
        ],
        maxCpu: [
          {
            required: true,
            message: "CPU(核)不能为空",
            trigger: "blur",
          },
        ],
        maxMemory: [
          {
            required: true,
            message: "内存(G)不能为空",
            trigger: "blur",
          },
        ],
        maxStorage: [
          {
            required: true,
            message: "存储空间(G)不能为空",
            trigger: "blur",
          },
        ],
        domainPrefix: [
          {
            required: true,
            message: "域名前缀不能为空",
            trigger: "blur",
          },
          {
            pattern: /^[a-zA-Z0-9-]{1,20}$/,
            message: "仅支持英文、数字、-，且不超过20个字符",
            trigger: "blur",
          },
        ],
        // phone: [
        //   {
        //     required: true,
        //     trigger: "blur",
        //     validator: validatorPhone,
        //   },
        // ],
      },
    };
  },
  mounted() {
    this.inintModal();
  },
  created() {},
  watch: {},
  methods: {
    inintModal() {
      this.form.domainPrefix = this.config.record.domainPrefix;
      if (this.config.type === "detail") {
        if (this.config.record.versionType === 1) {
          this.getStandardDetail();
        } else {
          this.getMajorDetail();
        }
      } else if (this.config.type === "tenantChange") {
        if (this.config.record.versionType === 1) {
          this.getStandardEdit();
        } else {
          this.getMajorEdit();
        }
      } else if (this.config.type === "tenantChange") {
        if (this.config.record.versionType === 1) {
          this.getStandardEdit();
        } else {
          this.getMajorEdit();
        }
      } else {
        this.rowVersion = this.config.record.rowVersion;
      }
    },

    handleCancel() {
      this.$emit("handleCancel");
    },
    //时间修改事件
    onTimeChange(dates, dateStrings) {
      this.form.revisitDay = dateStrings;
    },
    //获取标准版用户资源消耗
    getStandardDetail() {
      let id = this.config.id;
      sassTenantApi.getStandardDetail(id).then((res) => {
        if (res.success) {
          this.maxUsers = res.data.userNumber;
          this.maxApps = res.data.appNumber;
        } else {
          this.$message.error(res.errorMsg);
          this.$emit("handleCancel");
        }
      });
    },
    //获取专业版用户资源消耗
    getMajorDetail() {
      let id = this.config.id;
      sassTenantApi.getMajorDetail(id).then((res) => {
        if (res.success) {
          this.maxCpu = res.data.cpuUsageRate;
          this.maxMemory = res.data.memoryUsageRate;
          this.maxStorage = res.data.storageUsageRate;
        } else {
          this.$message.error(res.errorMsg);
          this.$emit("handleCancel");
        }
      });
    },
    //获取标准版用户资源消耗编辑
    getStandardEdit() {
      let id = this.config.id;
      sassTenantApi.getStandardEdit(id).then((res) => {
        if (res.success) {
          this.form.maxUsers = res.data.maxUsers;
          this.form.maxApps = res.data.maxApps;
          this.rowVersion = res.data.rowVersion;
        } else {
          this.$message.error(res.errorMsg);
          this.$emit("handleCancel");
        }
      });
    },
    //获取专业版用户资源消耗编辑
    getMajorEdit() {
      let id = this.config.id;
      sassTenantApi.getMajorEdit(id).then((res) => {
        if (res.success) {
          this.form.maxCpu = res.data.maxCpu;
          this.form.maxMemory = res.data.maxMemory;
          this.form.maxStorage = res.data.maxStorage;
          this.rowVersion = res.data.rowVersion;
        } else {
          this.$message.error(res.errorMsg);
          this.$emit("handleCancel");
        }
      });
    },
    //修改标准用户资源消耗接口
    changeStandardEdit() {
      this.confirmLoading = true;
      let params = {
        id: this.config.id,
        maxApps: this.form.maxApps,
        maxUsers: this.form.maxUsers,
        rowVersion: this.rowVersion,
      };
      sassTenantApi.changeStandardEdit(params).then((res) => {
        if (res.success) {
          this.confirmLoading = false;
          this.$emit("handleOk");
        } else {
          this.confirmLoading = false;
          this.$message.error(res.errorMsg);
          // this.$emit("handleCancel");
        }
      });
    },
    //修改专业用户接口
    changeMajorEdit() {
      this.confirmLoading = true;
      let params = {
        id: this.config.id,
        maxCpu: this.form.maxCpu,
        maxMemory: this.form.maxMemory,
        maxStorage: this.form.maxStorage,
        rowVersion: this.rowVersion,
      };
      sassTenantApi.changeMajorEdit(params).then((res) => {
        if (res.success) {
          this.confirmLoading = false;
          this.$emit("handleOk");
        } else {
          this.confirmLoading = false;
          this.$message.error(res.errorMsg);
          // this.$emit("handleCancel");
        }
      });
    },
    //修改域名前缀
    changeDomain() {
      this.confirmLoading = true;
      let params = {
        id: this.config.id,
        domainPrefix: this.form.domainPrefix,
        rowVersion: this.rowVersion,
      };
      sassTenantApi.changeDomain(params).then((res) => {
        if (res.success) {
          this.confirmLoading = false;
          this.$emit("handleOk");
        } else {
          this.confirmLoading = false;
          this.$message.error(res.errorMsg);
          // this.$emit("handleCancel");
        }
      });
    },
    //修改租户状态
    changeStatus() {
      this.confirmLoading = true;
      let params = {
        id: this.config.id,
        tenantStatus: this.config.type === "open" ? 1 : 2,
        rowVersion: this.rowVersion,
      };
      sassTenantApi.changeStatus(params).then((res) => {
        if (res.success) {
          this.confirmLoading = false;
          this.$emit("handleOk");
        } else {
          this.confirmLoading = false;
          this.$message.error(res.errorMsg);
          this.$emit("handleCancel");
        }
      });
    },

    handleOk() {
      if (this.config.type === "domainChange") {
         this.$refs.ruleForm.validate((valid) => {
          if (valid) {
           this.changeDomain();
          } else {
            return false;
          }
        });
        // if (this.form.domainPrefix) {
        //   this.changeDomain();
        // }
      } else if (
        this.config.type === "tenantChange" &&
        this.config.record.versionType === 1
      ) {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.changeStandardEdit();
          } else {
            return false;
          }
        });
      } else if (
        this.config.type === "tenantChange" &&
        this.config.record.versionType === 2
      ) {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.changeMajorEdit();
          } else {
            return false;
          }
        });
      } else {
        this.changeStatus();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input_width {
  width: 350px;
}
.carriage {
  display: flex;
  width: 400px;
  // background-color: aqua;
  margin: auto;
  justify-content: space-between;
}
.site {
  width: 180px;
  height: 40px;
  line-height: 40px;
  // background-color: red;
}
.right {
  text-align: right;
}
.notice_text {
  color: #33333357;
  font-size: 12px;
  margin-bottom: 10px;
  font-weight: 900;
}

.modal_text {
  text-align: left;
  font-weight: 600;
  height: 30px;
  line-height: 30px;
  img {
    margin-bottom: 4px;
  }
  .modal_logo {
    margin-left: 10px;
  }
}

/deep/.ant-table-body {
  overflow-x: hidden;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面的滑块*/
    border-radius: 5px;
    background: rgba(62, 63, 65, 0.5);
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道背景*/
    // display: none;
    width: 1px;
    padding: 0px;
    border-radius: 1px;
    background: rgba(0, 0, 0, 0.1);
  }
}

/deep/.ant-table-header {
  min-width: 0px !important;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
  }
}
</style>
<style>
.clickRowStyl {
  background-color: #52bbdb;
}

.ant-table-tbody > .clickRowStyl:hover > td {
  background-color: #00ed20;
}
</style>