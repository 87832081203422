import axios from "@/config/http";

export const sassTenantApi = {
  // 获取账号管理table数据
  getTableData(params) {
    return axios.get(
      "/cloud-platform-backend-facade/front-api/v1/tenant/allInfoByPage",
      { params }
    );
  },

  // 获取等级列表
  getLevelList() {
    return axios.get(
      "/cloud-platform-backend-facade/front-api/v1/tenant/tenantLevelDropDown"
    );
  },

  // 获取标准用户资源消耗接口
  getStandardDetail(params) {
    return axios.get(
      `/cloud-platform-backend-facade/front-api/v1/tenant/standardTenantResourceConsume/${params}`
    );
  },
  // 获取专业用户资源消耗接口
  getMajorDetail(params) {
    return axios.get(
      `/cloud-platform-backend-facade/front-api/v1/tenant/professionalTenantResourceConsume/${params}`
    );
  },

  // 获取标准用户资源消耗接口
  getStandardEdit(params) {
    return axios.get(
      `/cloud-platform-backend-facade/front-api/v1/tenant/standardTenantResource/${params}`
    );
  },
  // 获取专业用户资源消耗接口
  getMajorEdit(params) {
    return axios.get(
      `/cloud-platform-backend-facade/front-api/v1/tenant/professionalTenantResource/${params}`
    );
  },

  // 修改标准用户资源消耗接口
  changeStandardEdit(params) {
    return axios.put(
      "/cloud-platform-backend-facade/front-api/v1/tenant/standardTenantResource",
      params
    );
  },
  // 修改专业用户资源消耗接口
  changeMajorEdit(params) {
    return axios.put(
      "/cloud-platform-backend-facade/front-api/v1/tenant/professionalTenantResource",
      params
    );
  },

  //修改租户状态
  changeStatus(params) {
    return axios.put(
      "/cloud-platform-backend-facade/front-api/v1/tenant/tenantStatusChange",
      params
    );
  },

  //修改域名前缀
  changeDomain(params) {
    return axios.put(
      "/cloud-platform-backend-facade/front-api/v1/tenant/domainPrefixChange",
      params
    );
  },
};
