<template>
  <div>
    <div class="search_container">
      <div>
        <span>租户名称：</span>
        <a-input
          @keydown.native.enter="handleSearch"
          class="input_width"
          placeholder="请输入"
          v-model="tenantName"
        />
      </div>
      <div>
        <span>版本类型：</span>
        <a-select v-model="versionType" class="input_width">
          <a-select-option
            v-for="(item, index) in versionTypeList"
            :key="index"
            :value="item.value"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div>
        <span>租户等级：</span>
        <a-input
          @keydown.native.enter="handleSearch"
          class="input_width"
          placeholder="请输入"
          v-model="levelName"
        />
        <!-- <a-select @focus="getLevelList" v-model="levelId" class="input_width">
          <a-select-option
            v-for="(item, index) in levelIdList"
            :key="index"
            :value="item.id"
          >
            {{ item.levelName }}
          </a-select-option>
        </a-select> -->
      </div>
      <div>
        <span>状态：</span>
        <a-select v-model="tenantStatus" class="input_width">
          <a-select-option
            v-for="(item, index) in tenantStatusList"
            :key="index"
            :value="item.value"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
    </div>
    <div class="search_container add_button">
      <div>
        <span>租户所有者：</span>
        <a-input
          @keydown.native.enter="handleSearch"
          class="input_width"
          placeholder="请输入"
          v-model="tenantOwner"
        />
      </div>
      <div>
        <span>租户到期时间：</span>

        <a-range-picker
          v-model="expireDate"
          class="input_width"
          @change="onTimeChange"
        />
      </div>
      <div class="blank_contain"></div>
      <div class="blank_contain" style="text-align: right">
        <a-button @click="reset"> 重置 </a-button>
        <a-button
          style="margin-left: 20px"
          @click="handleSearch"
          type="primary"
        >
          查询
        </a-button>
      </div>
    </div>
    <div style="margin-top: 20px">
      <a-table
        :loading="loading"
        style="min-width: 1500px"
        :pagination="pagination"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="dataTable"
        :scroll="{ x: 1800, y: 379 }"
      >
        <span slot="versionType" slot-scope="text">
          <span v-if="text === 1">标准版</span>
          <span v-if="text === 2">专业版</span>
        </span>
        <span slot="levelName" slot-scope="text, record">
          <a @click="openModel('detail', record)">{{ text }}</a>
        </span>
        <span slot="expireTime" slot-scope="text">
          <span v-if="handleDateExpired(text) === true">{{ text }}</span>
          <span class="dateStyle" v-if="handleDateExpired(text) === false"
            >{{ text }} (已到期)</span
          >
        </span>
        <span slot="status" slot-scope="text">
          <span  v-if="text === 0">未启用</span>
          <span style="color: #52c41a" v-if="text === 1">启用中</span>
          <span style="color: #ff4d4f" v-if="text === 2">已停用</span>
        </span>
        <span slot="action" slot-scope="text, record">
          <a v-if="record.status === 1" @click="openModel('edit', record)"
            >修改</a
          >

          <a
            v-if="record.tenantStatus === 1"
            @click="openModel('close', record)"
          >
            停用
          </a>
          <a v-if="record.tenantStatus === 2" @click="openModel('open', record)"
            >启用</a
          >
          <a
            style="margin-left: 20px"
            @click="openModel('domainChange', record)"
            >修改域名前缀</a
          >
          <a
            style="margin-left: 20px"
             v-if="record.tenantStatus !== 0"
            @click="openModel('tenantChange', record)"
            >修改租户资源</a
          >
        </span>
      </a-table>
    </div>
    <ModelTag
      v-if="modelConfig.show"
      @handleCancel="handleCancel"
      @handleOk="handleOk"
      :config="modelConfig"
    ></ModelTag>
  </div>
</template>

<script>
import pagination from "@/mixins/Pagination";
import ModelTag from "./model.vue";
import { sassTenantApi } from "@/api/sassTenant.js";

export default {
  mixins: [pagination],
  components: {
    ModelTag,
  },
  data() {
    return {
      loading: false,
      startExpireDate: "",
      endExpireDate: "",
      tenantName: "",
      versionType: "",
      levelName: "",
      tenantStatus: "",
      tenantOwner: "",
      expireDate: null,

      modelConfig: {
        type: "add",
        id: "",
        show: false,
        record: null,
        title: "",
        width: "60%",
        okText: "保存",
        delArr: [],
        status: false,
      },
      versionTypeList: [
        // {
        //   name: "全部",
        //   value: '',
        // },
        {
          name: "标准版",
          value: 1,
        },
        {
          name: "专业版",
          value: 2,
        },
      ],
      tenantStatusList: [
        {
          name: "未启用",
          value: 0,
        },
        {
          name: "启用中",
          value: 1,
        },
         {
          name: "已停用",
          value: 2,
        },
      ],
      levelIdList: [],
      columns: [
        {
          title: "租户编号",
          dataIndex: "tenantCode",
          key: "tenantCode",
          align: "center",
        },
        {
          ellipsis: true,
          title: "租户名称",
          dataIndex: "tenantName",
          key: "tenantName",
          align: "center",
          width: "160px",
        },

        {
          ellipsis: true,
          title: "域名前缀",
          dataIndex: "domainPrefix",
          key: "domainPrefix",
          align: "center",
          width: "160px",
        },
        {
          title: "版本类型",
          dataIndex: "versionType",
          key: "versionType ",
          align: "center",
          scopedSlots: { customRender: "versionType" },
          // width: "110px",
        },
        {
          title: "租户到期时间",
          dataIndex: "expireTime",
          key: "expireTime",
          align: "center",
          scopedSlots: { customRender: "expireTime" },
          // width: "160px",
        },
        {
          title: "租户所有者",
          dataIndex: "frontUserName",
          key: "frontUserName",
          align: "center",
          // width: "160px",
        },

        {
          title: "租户所有者电话",
          dataIndex: "frontUserPhone",
          key: "frontUserPhone",
          align: "center",
        },

        {
          title: "租户等级",
          dataIndex: "levelName",
          key: "levelName",
          align: "center",
          scopedSlots: { customRender: "levelName" },
        },
        {
          title: "状态",
          dataIndex: "tenantStatus",
          key: "tenantStatus",
          align: "center",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "操作",
          align: "center",
          key: "action",
          scopedSlots: { customRender: "action" },
          width: "310px",
            fixed: 'right',
        },
      ],
      dataTable: [],
    };
  },
  mounted() {
    // this.getLevelList();
    this.getData();
  },
  methods: {
    //判断日期是否过期
    handleDateExpired(val) {
      const date = new Date(val);
      if (val) {
        if (date.getTime() < Date.now()) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    //获取等级下拉列表
    getLevelList() {
      this.loading = true;
      sassTenantApi.getLevelList().then((res) => {
        if (res.success) {
          this.loading = false;
          this.levelIdList = res.data;
        } else {
          this.loading = false;
          this.$message.error(res.errorMsg);
        }
      });
    },
    //时间修改事件
    onTimeChange(dates, dateStrings) {
      this.expireDate = dateStrings;
      this.startExpireDate = dateStrings[0];
      this.endExpireDate = dateStrings[1];
    },
    //重置
    reset() {
      this.tenantStatus = "";
      this.startExpireDate = "";
      this.endExpireDate = "";
      this.tenantName = "";
      this.versionType = "";
      this.levelName = "";
      this.expireDate = "";
      this.tenantOwner = "";
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getData();
    },
    //查询数据接口
    getData() {
      this.loading = true;
      let params = {
        tenantName: this.tenantName,
        versionType: this.versionType,
        levelName: this.levelName,
        tenantStatus: this.tenantStatus,
        frontUserName: this.tenantOwner,
        startExpireDate: this.startExpireDate,
        endExpireDate: this.endExpireDate,
        page: this.pagination.current,
        size: this.pagination.pageSize,
      };
      sassTenantApi.getTableData(params).then((res) => {
        if (res.success) {
          this.loading = false;
          this.pagination.total = Number(res.data.total);
          this.dataTable = res.data.records;
        } else {
          this.loading = false;
          this.$message.error(res.errorMsg);
        }
      });
    },
    //查询
    handleSearch() {
      this.pagination.current = 1;
      this.getData();
    },

    //调用弹框事件
    openModel(type, record) {
      this.modelConfig.id = record ? record.id : null;
      this.modelConfig.type = type;
      this.modelConfig.show = true;
      if (type === "domainChange") {
        this.modelConfig.record = record;
        this.modelConfig.title = "修改域名前缀";
        this.modelConfig.okText = "确定";
        this.modelConfig.width = "600px";
        this.modelConfig.status = false;
      } else if (type === "open") {
        this.modelConfig.record = record;
        this.modelConfig.title = "启用账号";
        this.modelConfig.width = "600px";
        this.modelConfig.okText = "确定";
      } else if (type === "tenantChange") {
        this.modelConfig.record = record;
        this.modelConfig.title = "修改租户资源";
        this.modelConfig.width = "600px";
        this.modelConfig.okText = "确定";
      } else if (type === "close") {
        this.modelConfig.record = record;
        this.modelConfig.title = "停用账号";
        this.modelConfig.width = "600px";
        this.modelConfig.okText = "确定";
      } else {
        this.modelConfig.record = record;
        this.modelConfig.width = "600px";
        if (record.versionType === 1) {
          this.modelConfig.title = "标准版租户资源消耗情况";
        } else {
          this.modelConfig.title = "专业版租户资源消耗情况";
        }
      }
    },

    //弹框关闭
    handleCancel() {
      this.modelConfig.show = false;
    },
    //弹框保存按钮
    handleOk() {
      this.modelConfig.show = false;
      if (this.modelConfig.type === "open") {
        this.$message.success("启用成功！");
      } else if (this.modelConfig.type === "close") {
        this.$message.success("停用成功！");
      } else {
        this.$message.success("保存成功！");
      }

      this.reset();
    },
    // 分页改变
    onSizeChange(current, size) {
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.getData();
    },
    //分页
    onPage(current) {
      this.pagination.current = current;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.dateStyle {
  color: red;
}
.input_width {
  width: 240px;
}
.blank_contain {
  width: 340px;
}
.search_container {
  display: flex;
  justify-content: space-between;
  min-width: 1500px;
  overflow: auto;
  // &>div{
  //   // width: 25%;
  // }
  & > div > span:first-child {
    // background-color: aqua;
    display: inline-block;
    width: 100px;
    text-align: right;
  }
}
.add_button {
  margin-top: 20px;
  margin-bottom: 20px;
}
.popover_contain {
  height: 30px;
  width: 120px;
  cursor: pointer;
  line-height: 30px;
  text-align: center;
  border-radius: 4px;
}
.popover_contain:hover {
  background-color: #f3f3f3;
}

// /deep/.ant-table-body {
//   overflow-x: hidden;
//   &::-webkit-scrollbar {
//     /*滚动条整体样式*/
//     width: 4px;
//     height: 4px;
//   }

//   &::-webkit-scrollbar-thumb {
//     /*滚动条里面的滑块*/
//     border-radius: 5px;
//     background: rgba(62, 63, 65, 0.5);
//   }

//   &::-webkit-scrollbar-track {
//     /*滚动条里面轨道背景*/
//     // display: none;
//     width: 4px;
//     padding: 0px;
//     border-radius: 1px;
//     background: rgba(0, 0, 0, 0.1);
//   }
// }
.modal_reset {
  color: rgb(136, 131, 131);
  font-size: 14px;
  padding-left: 36px;
  font-weight: 200;
}
// /deep/.ant-table-header {
//   min-width: 0px !important;
//   &::-webkit-scrollbar {
//     /*滚动条整体样式*/
//     width: 4px;
//   }
// }
  /deep/.ant-table {
    min-height: 379px;
    }
</style>